import { IUnauthorizedRoute } from './routesTypes';
import { RouteRecordRaw } from 'vue-router';
const routes: RouteRecordRaw[] = [
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/auth/RegisterView.vue'),
    meta: {
      layout: 'AuthLayout',
      layoutMode: 'registration',
      title: 'Регистрация',
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/auth/LoginView.vue'),
    meta: {
      layout: 'AuthLayout',
      layoutMode: 'login',
      title: 'Авторизация',
    },
  },
  {
    path: '/mp',
    name: 'MobileApp',
    component: () => import('@/views/auth/MobileApp.vue'),
    meta: {
      layout: 'EmptyLayout',
      title: 'Скачать мобильное приложение',
    },
  },
  {
    path: '/tech-support',
    name: 'TechSupport',
    component: () => import('@/views/techSupport/TechSupportView.vue'),
    meta: {
      layout: 'TechSupportLayout',
      title: 'Чат поддержки'
    },
  },

  {
    path: '/dom-24',
    name: 'YourHouseIsUnderYourControl',
    component: () =>
      import('../views/specialArticles/YourHouseIsUnderYourControl.vue'),
    meta: {
      layout: 'NonAuthorizedPagesLayout',
      title: 'Ваш дом под вашим контролем',
    },
  },

  {
    path: '/triniti',
    name: 'YourHouseIsUnderYourControlAlt',
    component: () =>
      import('../views/specialArticles/YourHouseIsUnderYourControlAlt.vue'),
    meta: {
      layout: 'NonAuthorizedPagesLayout',
      title: 'Ваш дом под вашим контролем',
    },
  },
  
  // UI-Kit --------------------------------------------------------------------
  {
    path: '/ui-kit/',
    name: 'UiKit',
    component: () => import('@/views/uiKit/IndexView.vue'),
    meta: {
      layout: 'UiKitLayout',
    },
    children: [
      {
        path: 'colors',
        name: 'UiKit.Colors',
        component: () => import('@/views/uiKit/ColorsView/ColorsView.vue'),
        meta: {
          title: 'Цветовая система',
        },
      },
      {
        path: 'typography',
        name: 'UiKit.Typography',
        component: () =>
          import('@/views/uiKit/TypographyView/TypographyView.vue'),
        meta: {
          title: 'Типографика',
        },
      },
      {
        path: 'icons',
        name: 'UiKit.Icons',
        component: () => import('@/views/uiKit/IconsView/IconsView.vue'),
        meta: {
          title: 'Иконки',
        },
      },
      {
        path: 'buttons',
        name: 'UiKit.Buttons',
        component: () => import('@/views/uiKit/ButtonsView/ButtonsView.vue'),
        meta: {
          title: 'Кнопки',
        },
      },
      {
        path: 'badges',
        name: 'UiKit.Badges',
        component: () => import('@/views/uiKit/BadgesView/BadgesView.vue'),
        meta: {
          title: 'Баджи и ярлыки',
        },
      },
      {
        path: 'lists',
        name: 'UiKit.Lists',
        component: () => import('@/views/uiKit/ListsView/ListsView.vue'),
        meta: {
          title: 'Списки',
        },
      },
      {
        path: 'cards',
        name: 'UiKit.Cards',
        component: () => import('@/views/uiKit/CardsView/CardsView.vue'),
        meta: {
          title: 'Карточки',
        },
      },
      {
        path: 'forms',
        name: 'UiKit.Forms',
        component: () => import('@/views/uiKit/FormsView/FormsView.vue'),
        meta: {
          title: 'Формы',
        },
      },
      {
        path: 'other',
        name: 'UiKit.Other',
        component: () => import('@/views/uiKit/OtherView/OtherView.vue'),
        meta: {
          title: 'Разное',
        },
      },
      {
        path: 'chat',
        name: 'UiKit.Chat',
        component: () => import('@/views/uiKit/ChatView/ChatView.vue'),
        meta: {
          title: 'Чат',
        },
      },
    ],
  },
];

export const unauthorizedRoutes: IUnauthorizedRoute[] = routes.map((route) => ({
  ...route,
  meta: { ...route.meta, requiresAuth: false },
}));
